// Hooks
import { useTheme } from 'styled-components';

// Components
import { NoOrgsWrapper } from './styled';
import { CustomText } from 'typography/Text';
import { IconSingleOrg } from 'assets/svg';

// Hooks
import { useTranslations } from 'hooks/useTranslations';

export interface NoOrganisationsProps {
  searchTerm?: string;
}

export const NoOrganisations = ({ searchTerm }: NoOrganisationsProps) => {
  const { palette } = useTheme();
  const { translate } = useTranslations('account');

  // If we have a search term, show the "no matches" message
  if (searchTerm) {
    return (
      <NoOrgsWrapper>
        <IconSingleOrg />
        <CustomText type="hint" color={palette.fonts.bodyLight}>
          {translate('no_orgs_match')}
        </CustomText>
      </NoOrgsWrapper>
    );
  }

  // Default case - "no other organizations"
  return (
    <NoOrgsWrapper>
      <IconSingleOrg />
      <CustomText type="hint" color={palette.fonts.bodyLight}>
        {translate('single_organisation')}
      </CustomText>
    </NoOrgsWrapper>
  );
};
