import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconDown } from 'assets/svg';

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.4375rem;
  height: 2.0625rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-auto-rows: auto;
`;

export const GridTopRow = styled.div`
  display: grid;
  grid-row: 1 / span 1;
  grid-column: 1 / span 2;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
`;

export const LeftInternalGridContainer = styled.div`
  height: fit-content;
  display: grid;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-auto-rows: auto;
`;
export const RightInternalGridContainer = styled.div`
  height: fit-content;
  display: grid;
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-auto-rows: auto;
`;

export const Section = styled.section`
  place-self: flex-start;
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.palette.background.panel};
  box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};
  border-radius: 0.25rem;
`;

export const AnnouncementGridItem = styled(Section)`
  height: 100%;
  min-height: 8rem;
  position: relative;
  padding: 1.5rem;
  justify-content: space-between;
  flex-direction: row;
`;

export const SlackGridItem = styled(Section)`
  height: 100%;
  min-height: 8rem;
  position: relative;
  padding: 1.5rem;
  justify-content: space-between;
  flex-direction: row;
`;

export const SystemsNeedApprovalGridItem = styled(Section)`
  height: 100%;
  min-height: 8rem;
  padding: 1.5rem;
`;

export const SystemsLink = styled(Link)`
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.fills.primary};
  }
`;

export const SystemsLinkA = styled.a`
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.fills.primary};
  }
`;

export const SystemsEnrolledGridItem = styled(Section)`
  height: 100%;
  min-height: 8rem;
  position: relative;
  flex-direction: column;
  padding: 1.5rem;

  overflow: hidden;
`;

export const RecentActivityGridItem = styled(Section)`
  grid-area: 2 / 1 / span 1 / span 2;
  padding: 1.5rem 1.75rem;
  flex-direction: column;
  min-height: 18.875rem;
`;

export const GettingStartedGridItem = styled(Section)`
  grid-area: 2 / 1 / span 1 / span 2;
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
`;

export const RecentlyEnrolledGridItem = styled(Section)`
  grid-area: 3 / 1 / span 1 / span 2;
  padding: 1.5rem 1.75rem;
  flex-direction: column;
  min-height: 22rem;
`;

export const TutorialsGridItem = styled(Section)`
  grid-area: 3 / 1 / span 1 / span 2;
  padding: 1.5rem 1.75rem;
  flex-direction: column;
  min-height: 18rem;
`;

interface IIconProps {
  $isActive: boolean;
}

export const IconContainer = styled.div<IIconProps>`
  position: absolute;
  top: 1.75rem;
  right: 0.25rem;

  ${({ theme }) => theme.media_queries.s1440} {
    right: 1.5rem;
  }

  height: 1.875rem;
  width: 1.875rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2px;
  background: ${({ $isActive: isActive, theme }) =>
    isActive ? theme.palette.fills.iconHover : 'transparent'};

  transition: all 0.1s ease-in-out;
`;

export const RightDirectionIcon = styled(IconDown)<IIconProps>`
  transform: rotate(-90deg);
  height: 1.875rem;
  width: 1.875rem;
  path {
    fill: ${({ $isActive: isActive, theme }) =>
      isActive ? theme.palette.icons.primary : theme.palette.icons.disabled};
  }

  transition: all 0.1s ease;
`;
