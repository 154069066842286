import { css } from 'styled-components';

export const tableCss = css`
  width: 100%;
  border-collapse: collapse;

  td {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
  td:not(:nth-child(2)):not(:nth-child(3)) {
    white-space: nowrap !important;
  }

  td:nth-child(1) {
    width: 2.25rem;

    padding-top: 0.6rem !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0.75rem !important;

    vertical-align: top;
  }

  td:nth-child(2) {
    padding-left: 0.75rem !important;
    white-space: break-spaces;
    word-break: break-word;
  }
  td:nth-child(3) {
    white-space: break-spaces;
    word-break: break-word;
    width: 20.625rem;
  }
  td:nth-child(4) {
    width: 17.625rem;
  }

  tbody tr {
    cursor: default;
  }
`;
