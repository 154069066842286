// libs
import { memo } from 'react';

// components
import Skeleton from 'react-loading-skeleton';
import { CustomText } from 'typography/Text';
import { SystemStatusBadge } from 'components';

// hooks
import styled, { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// types
import { ISystemSummaryModel } from 'types';

// utils
import { formatTime } from 'utils/timeFormatting';

interface IConnectedFrom
  extends Required<
    Pick<ISystemSummaryModel, 'isEnabled' | 'connectedFrom' | 'state' | 'lastSeen'>
  > {
  isLoading: boolean;
}

const Container = styled.div`
  position: relative;
`;

export const Status = memo(
  ({ isEnabled, isLoading, connectedFrom, state, lastSeen, ...rest }: IConnectedFrom) => {
    const {
      palette: { fonts },
    } = useTheme();
    const { translate } = useTranslations('systems');

    const getStatusText = () => {
      if (state === 'Connected') return connectedFrom;
      if (state === 'Disabled') return translate('disabled');
      if (state === 'Disconnected') {
        return lastSeen
          ? `${translate('disconnected')} ${formatTime({ date: lastSeen, format: 'D MMM YYYY' })}`
          : translate('disconnected');
      }
      return null;
    };

    return (
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Container>
            <SystemStatusBadge state={state} />
            <CustomText type="body-2" color={isEnabled ? fonts.title : fonts.disabled} {...rest}>
              {getStatusText()}
            </CustomText>
          </Container>
        )}
      </>
    );
  }
);
