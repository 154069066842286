import styled, { css } from 'styled-components';
import { stylesMap } from 'typography/Text';

type Props = {
  height?: string;

  empty: boolean;
};

export const SelectContainer = styled.div`
  position: relative;
  .ant-select-dropdown {
    padding: 0;
    border: 0.0625rem solid ${({ theme }) => theme.palette.background.iconBackground};
    border-radius: 0.25rem;
    box-shadow: ${({ theme }) => theme.palette.elevationShadows[200]};

    .ant-select-item {
      border-radius: 0.25rem;
      padding: 0 0.75rem;

      .ant-select-item-option-content {
        padding: 0.5625rem 0;
      }
    }
  }
`;

export const selectCss = ({ empty }: Props) => css`
  border: none;
  box-sizing: border-box;

  width: 100%;
  height: auto !important;
  border-radius: 0.25rem !important;

  min-height: 2.5rem;

  [aria-live='polite'] {
    display: none;
  }

  .ant-select-selection-search {
    transition: height 0.1s linear;
    height: ${empty ? '1.375rem' : 0};
    width: 100% !important;

    input {
      height: 100%;
    }
  }

  & > :not(.ant-select-selector) {
    // without it dropdown would not adjust it's height relative to input
    position: relative !important;
  }

  .ant-select-selection-search {
    flex: 1;
    margin-left: 0;
    // space for icon
    margin-right: 1.5rem;
  }

  .ant-select-selection-search:first-child > .ant-select-selection-search-input {
    margin-left: 0 !important;
  }

  .ant-select-item-option:active {
    background-color: ${({ theme }) => theme.palette.background.focus};
  }

  .select-icon-down {
    transition: transform 0.1s ease 0s;
  }

  &.ant-select-open .select-icon-down {
    transform: rotate(-180deg);
  }

  .ant-select-selection-item {
    display: none;
  }

  .ant-select-selection-item {
    border-radius: 0.25rem;
    background: none;
    border-color: transparent;

    :hover {
      background-color: ${({ theme }) => theme.palette.background.sections};
    }
  }

  .ant-select-item-option-selected {
    background: none;
  }

  .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.palette.background.base};
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;

    min-height: inherit;

    height: 100% !important;
    width: 100% !important;
    box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]} !important;
    border-radius: 0.25rem !important;

    border: 0.075rem solid ${({ theme }) => theme.palette.background.iconBackground} !important;

    padding: 0 0.25rem 0.5rem 0.625rem !important;

    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
  .ant-select-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
    width: auto;
  }
  .ant-select-selection-placeholder {
    ${stylesMap['body-2']}
    color: ${({ theme }) => theme.palette.fonts.disabled};
  }

  :hover {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.palette.fills.stroke} !important;
    }
  }

  :focus-within {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.palette.fills.primary} !important;
    }
  }

  input {
    ${stylesMap['body-2']}
    color: ${({ theme }) => theme.palette.fonts.title};
  }

  .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
    width: 100%;
  }

  .ant-select-selection-search {
    height: 0;
  }

  :focus-within {
    .ant-select-selector {
      border-color: ${({ theme }) => theme.palette.fills.primary};

      .ant-select-selection-search {
        transition: height 0.1s linear;
        height: 1.375rem;
      }
    }
  }

  .ant-select-selection-overflow-item > span {
    display: block;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
`;

export const DropdownContainer = styled.div`
  .rc-virtual-list-holder {
    padding: 0.5rem 0;
  }

  width: 100%;
  max-height: 33.5rem;

  .rc-virtual-list-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.background.iconBackground} !important;
  }

  .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    text-overflow: clip;
    white-space: normal;
    min-height: 2.5rem;

    svg.add-icon {
      display: none;

      height: 1.2rem;

      path {
        fill: ${({ theme }) => theme.palette.icons.secondary};
      }
    }

    :hover {
      svg.add-icon {
        display: initial;
      }
    }
  }
  .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.palette.background.base};
  }
  .ant-select-item-option-selected {
    display: none;
  }
`;

export const EmptyWrapper = styled.div`
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span:first-child {
    margin-bottom: 0.5rem;
  }
`;

export const OptionTextContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span:nth-child(2) {
    margin-top: -0.2rem;
    margin-bottom: 0.2rem;
  }
`;

export const TagItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
  :hover .ordering-icons {
    visibility: visible !important;
  }
`;

export const OrderingIconContainer = styled.div`
  display: flex;
  visibility: hidden;
`;

export const OrderingSingleIconContainer = styled.div<{ disabled: boolean }>`
  width: 1.625rem;
  height: 1.625rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 0.25rem !important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.icons.disabled : theme.palette.icons.secondary};

  :hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? 'none' : theme.palette.background.iconBackground};
  }
`;
