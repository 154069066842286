// libs
import React, { useCallback, useState } from 'react';

// components
import { CustomButton, Input } from 'components';
import { BlockWrapper, ContentWrapper } from 'modules/Account/styled';
import { CustomText, StyledSubtitle } from 'typography/Text';
import { InputWrapper } from './styled';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import {
  selectAccountFirstName,
  selectAccountLastName,
  selectAccountUserName,
  selectAccountMemberSince,
} from 'redux/selectors';

// actions
import { updateAccount } from 'redux/actions/account';

// utils
import { formatTime } from 'utils';

// types
import { IUserAccount } from 'types';

type NameState = {
  firstName: IUserAccount['firstName'];
  lastName: IUserAccount['lastName'];
};

export const PersonalInfoBlockLocal = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('account');

  const {
    palette: { fonts },
  } = useTheme();

  const firstName = useSelector(selectAccountFirstName);
  const lastName = useSelector(selectAccountLastName);
  const email = useSelector(selectAccountUserName);
  const memberSince = useSelector(selectAccountMemberSince);

  const [userName, setUserName] = useState<NameState>({
    firstName,
    lastName,
  });

  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target;
      setUserName({
        ...userName,
        [name]: value,
      });
    },
    [userName]
  );

  const inputValidation = useCallback(
    (value: string | null) => {
      let message = '';

      if (value === null) {
        return { message };
      }

      if (!value.length) {
        message = translate('no_empty_field');
      }
      if (value.length > 64) {
        message = translate('no_long_field');
      }

      return { message };
    },
    [translate]
  );

  const onUpdateUser = useCallback(() => {
    if (
      inputValidation(userName.firstName)?.message === '' &&
      inputValidation(userName.lastName)?.message === ''
    ) {
      dispatch(updateAccount(userName));
    }
  }, [dispatch, inputValidation, userName]);

  return (
    <BlockWrapper mt="2.5rem">
      <ContentWrapper>
        <StyledSubtitle>{translate('personal_info')}</StyledSubtitle>
        <CustomText color={fonts.bodyLight} type="body-2">
          {email}
        </CustomText>
        <CustomText color={fonts.bodyLight} type="body-2">
          {translate('member_since')}:{' '}
          {formatTime({ date: memberSince, format: 'ddd, D MMM YYYY HH:mm GMT' }) || ''}
        </CustomText>
      </ContentWrapper>
      <ContentWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="firstName"
            value={userName.firstName || ''}
            placeholder={translate('first_name_placeholder')}
            onChange={onChangeName}
            label={translate('first_name')}
            mRight="1rem"
            error={inputValidation(userName.firstName)}
            showStateIcon={firstName !== userName.firstName}
          />
          <Input
            type="text"
            name="lastName"
            value={userName.lastName || ''}
            placeholder={translate('last_name_placeholder')}
            onChange={onChangeName}
            label={translate('last_name')}
            error={inputValidation(userName.lastName)}
            showStateIcon={lastName !== userName.lastName}
          />
        </InputWrapper>
        <CustomButton onClick={onUpdateUser} size="large">
          {translate('update')}
        </CustomButton>
      </ContentWrapper>
    </BlockWrapper>
  );
};
