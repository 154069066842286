import { Select as AntdSelect } from 'antd';

import styled from 'styled-components';
import { stylesMap } from 'typography/Text';

export const DropdownContainer = styled.div`
  .rc-virtual-list-holder {
    padding: 0.5rem 0.25rem;
  }

  .ant-select-item {
    border-radius: 0.25rem;
    padding: 0.5625rem 0.75rem;
  }

  .tag-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: initial;
  }

  .rc-virtual-list-holder-inner > .ant-select-item-option-selected {
    display: none;
  }

  .rc-virtual-list-holder-inner > div.tag-shown-option span.ant-badge-status {
    margin-left: 0.5rem;
  }
`;

export const TagsSelectContainer = styled.div`
  .ant-select-dropdown {
    padding: 0;
  }

  display: flex;
  flex-direction: column;
`;

export const TagIconCloseContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

interface ISelectProps {
  $empty?: boolean;
  $error?: boolean;
  $width?: string;
}

export const Select = styled(AntdSelect)<ISelectProps>`
  width: ${({ $width }) => $width || '100%'};
  max-height: 100%;

  [aria-live='polite'] {
    display: none;
  }

  & > :not(.ant-select-selector) {
    // without it dropdown would not adjust it's height relative to input
    position: relative !important;
  }

  .ant-select-selector::after {
    display: none;
  }
  /* we duplicate class here to increase our style specificity so it would override antd styles,
  otherwise we would need to add !important to all styles, but it will interfere with adding !important to error styles,
  which needs to have precedence over other css */

  &.ant-select.ant-select.ant-select.ant-select.ant-select {
    .ant-select-selector {
      & > span:not(:last-child, :nth-last-child(2)) {
        margin-bottom: 0.375rem;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.5rem;
      height: ${({ $empty }) => $empty && '2.5rem'};

      box-shadow: ${({ theme }) => theme.palette.elevationShadows[100]} !important;
      border: 0.075rem solid
        ${({ theme, $error }) =>
          $error
            ? `${theme.palette.state.error} !important`
            : theme.palette.background.iconBackground};
      border-radius: 0.25rem;
      ${({ theme, $error }) =>
        $error && `box-shadow: ${theme.palette.fieldShadows.error} !important`};

      .ant-select-selection-search {
        margin-left: 0 !important;
        transition: height 0.1s linear;
        height: ${({ $empty }) => ($empty ? '1.375rem' : 0)};
        width: 100% !important;

        input {
          height: 100%;
        }
      }
      span {
        max-width: 100%;
      }

      .ant-select-selection-overflow {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.25rem;

        &-item {
          align-self: flex-start;
        }
      }
    }

    :hover {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.palette.fills.stroke};
      }
    }

    :focus-within {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.palette.fills.primary};

        .ant-select-selection-search {
          transition: height 0.1s linear;
          height: 1.375rem;
        }
      }
    }
    .ant-select-selection-search-input {
      ${stylesMap['body-1']}
      color:${({ theme }) => theme.palette.fonts.title};
    }

    div.ant-select-selector > .ant-select-selection-item {
      background: ${({ theme }) => theme.palette.background.focus};

      border: 0.75rem solid ${({ theme }) => theme.palette.fills.iconHover};
    }
  }
`;
