// libs
import { css } from 'styled-components';

// components
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import {
  StyledHeader,
  StyledLogoContainer,
  StyledButton,
  StyledTopMenu,
  StyledTopMenuItem,
} from './Styled';
import { StyledRow, StyledCol } from 'components';
import { CustomText } from 'typography/Text';
import { IconDown, IconNavbar } from 'assets/svg';
import { NavLink } from 'react-router-dom';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

// actions
import { doLogin, doLogout } from 'redux/actions/user';

// selectors
import {
  selectUserIsLoggedIn,
  selectUserProfileName,
  selectOrgId,
  selectPartnerAccess,
} from 'redux/selectors';

// constants
import { LINK_TO_DOCS, ROUTE_PATHS, SIDE_MENU_ROUTE_PATHS } from 'appConstants';

const linkStyles = css`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;

  :hover {
    background-color: ${props => props.theme.palette.background.base};
  }
  :focus {
    background-color: ${props => props.theme.palette.background.focus};
  }
`;

export const TopBar = () => {
  const dispatch = useDispatch();

  const { translate } = useTranslations('menu');

  const {
    palette: { fonts, state, icons },
    text_sizes,
  } = useTheme();

  const [isDropdownOpened, setDropdownOpened] = useState(false);

  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const profileName = useSelector(selectUserProfileName);
  const orgId = useSelector(selectOrgId);
  const partnerAccess = useSelector(selectPartnerAccess);

  const onLogin = useCallback(() => dispatch(doLogin()), [dispatch]);
  const onLogout = useCallback(() => dispatch(doLogout()), [dispatch]);
  const LoginMenu = useCallback(() => {
    return (
      <StyledTopMenu>
        <StyledTopMenuItem>
          <NavLink to={ROUTE_PATHS.account} onClick={() => setDropdownOpened(false)}>
            <CustomText type="body-2" color={fonts.bodyLight}>
              {translate('item_my_account')}
            </CustomText>
          </NavLink>
        </StyledTopMenuItem>
        <StyledTopMenuItem onClick={onLogout} key="item_logout" $error={true}>
          <CustomText type="body-2" color={state.error}>
            {translate('logout')}
          </CustomText>
        </StyledTopMenuItem>
      </StyledTopMenu>
    );
  }, [fonts.bodyLight, onLogout, state.error, translate]);

  const IconStyle = {
    fontSize: text_sizes[4].fontSize,
    display: 'flex',
    color: icons.disabled,
    width: '1rem',
  };
  const IconStyleDD = {
    fontSize: text_sizes[2].fontSize,
    transition: 'all 0.1s ease',
    color: icons.secondary,
    transform: isDropdownOpened ? 'rotate(-180deg)' : 'rotate(0deg)',
    width: '1.5rem',
  };

  return (
    <StyledHeader>
      <StyledRow
        $fullHeight={true}
        justify="space-between"
        css={css`
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
        `}
      >
        <StyledCol
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Link
            aria-label={translate('aria_labels.home')}
            to={
              orgId ? `/org/${orgId}${SIDE_MENU_ROUTE_PATHS.dashboard}` : `${ROUTE_PATHS.account}`
            }
            css={css`
              height: 100%;
              .ant-image {
                height: 100%;
              }
            `}
          >
            <StyledLogoContainer>{<IconNavbar />}</StyledLogoContainer>
          </Link>
        </StyledCol>
        <StyledCol $fullHeight $asFlex>
          {partnerAccess && (
            <a
              aria-label={translate('aria_labels.partner_portal')}
              href={process.env.ENC_PARTNER_HOST}
              target="_blank"
              rel="noopener noreferrer"
              css={linkStyles}
            >
              <CustomText
                type="body-2"
                color={fonts.bodyDark}
                css={css`
                  margin-right: 0.5rem;
                `}
              >
                {translate('partner_portal')}
              </CustomText>
            </a>
          )}
          <a
            aria-label={translate('aria_labels.documentation')}
            href={LINK_TO_DOCS}
            target="_blank"
            rel="noopener noreferrer"
            css={linkStyles}
          >
            <CustomText
              type="body-2"
              color={fonts.bodyDark}
              css={css`
                margin-right: 0.5rem;
              `}
            >
              {translate('documentation')}
            </CustomText>
          </a>

          {isLoggedIn ? (
            <Dropdown
              overlay={LoginMenu}
              trigger={['click']}
              onVisibleChange={(visible: boolean) => setDropdownOpened(visible)}
            >
              <StyledButton
                type="text"
                tabIndex={0}
                css={css`
                  :hover {
                    background-color: ${props => props.theme.palette.background.base};
                  }
                  :focus {
                    background-color: ${props => props.theme.palette.background.focus};
                  }
                `}
              >
                <UserOutlined style={IconStyle} />
                {profileName ? (
                  <CustomText
                    type="body-2"
                    color={fonts.bodyDark}
                    css={css`
                      margin-right: 0.5rem;
                    `}
                  >
                    {profileName}
                  </CustomText>
                ) : (
                  ''
                )}
                <IconDown style={IconStyleDD} />
              </StyledButton>
            </Dropdown>
          ) : (
            <StyledButton onClick={onLogin}>
              <CustomText type="body-2">{translate('login')}</CustomText>
            </StyledButton>
          )}
        </StyledCol>
      </StyledRow>
    </StyledHeader>
  );
};
