import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { translator } from 'utils/nonComponentTranslations';
import { defaultTimeZone } from 'appConstants';
import i18next from 'i18next';

const translate = translator('global');

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

const timeFormats = {
  ago: 'ago',
  'D MMM YYYY': 'D MMM YYYY',
  'DD MMM YYYY': 'DD MMM YYYY',
  'ddd, MMM D, YYYY h:mm A GMT': 'ddd, MMM D, YYYY h:mm A GMT',
  'ddd, D MMM YYYY HH:mm GMT': 'ddd, D MMM YYYY HH:mm GMT',
  time_remaining: 'time_remaining',
  'Do MMMM, YYYY at h:mm A zzz': 'Do MMMM, YYYY at h:mm A zzz',
};

export const formatTime = ({
  date,
  format = 'ago',
}: {
  date: string | number;
  format?: keyof typeof timeFormats;
}) => {
  switch (format) {
    case timeFormats.ago: {
      const dateTo = dayjs().to(dayjs(date));
      return dateTo === 'a day ago' && i18next.language === 'en' ? 'Yesterday' : dateTo;
    }
    case timeFormats['D MMM YYYY']:
      return `${dayjs(date).format('D MMM YYYY')}`;
    case timeFormats['DD MMM YYYY']:
      return `${dayjs(date).format('DD MMM YYYY')}`;
    case timeFormats['ddd, MMM D, YYYY h:mm A GMT']:
      return `${dayjs(date).utc().format('ddd, MMM D, YYYY h:mm A')} GMT`;
    case timeFormats['ddd, D MMM YYYY HH:mm GMT']:
      return `${dayjs(date).utc().format('ddd, D MMM YYYY HH:mm')} GMT`;
    case timeFormats['time_remaining']:
      const currentDate = dayjs();

      const months = dayjs(date).diff(currentDate, 'month');
      const monthsText = months ? ` ${months} ${translate('time.months', { count: months })}` : '';

      const weeksDate = months ? currentDate.add(months, 'month') : currentDate;
      const weeks = dayjs(date).diff(weeksDate, 'week');
      const weeksText = weeks ? ` ${weeks} ${translate('time.weeks', { count: weeks })}` : '';

      const daysDate = weeks ? weeksDate.add(weeks, 'week') : weeksDate;
      const days = dayjs(date).diff(daysDate, 'day');
      const daysText = days ? ` ${days} ${translate('time.days', { count: days })}` : '';

      const hoursDate = days ? daysDate.add(days, 'day') : daysDate;
      const hours = dayjs(date).diff(hoursDate, 'hour');
      const hoursText = hours ? ` ${hours} ${translate('time.hours', { count: hours })}` : '';

      const minutesDate = hoursDate ? hoursDate.add(hours, 'hour') : hoursDate;
      const minutes = dayjs(date).diff(minutesDate, 'minute');
      const minutesText = minutes
        ? ` ${minutes} ${translate('time.minutes', { count: minutes })}`
        : '';

      const secondsDate = minutesDate ? minutesDate.add(minutes, 'minute') : minutesDate;
      const seconds = dayjs(date).diff(secondsDate, 'second');
      const secondsText =
        seconds && months === 0 && weeks === 0 && days === 0 && hours === 0 && minutes === 0
          ? `${translate('time.less_than_minute')}`
          : '';

      if (months < 0 || weeks < 0 || days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
        return null;
      }

      return [monthsText, weeksText, daysText, hoursText, minutesText, secondsText]
        .filter(element => !!element)
        .join(', ');
    case timeFormats['Do MMMM, YYYY at h:mm A zzz']:
      return `${dayjs(date).format('Do MMMM, YYYY')} ${translate('time.at')} ${dayjs(date).format(
        'h:mm A'
      )} ${getLocalTimezoneAbbreviation()}`;
  }
};

export const secondsToDate = (date: string) => dayjs(date).diff(dayjs(), 'seconds');

// Alternative - 'ddd MMM D YYYY H:mm:ss'
export const localizedTime = (date: string) => dayjs(date).format('llll');

export const getLocalTimezoneAbbreviation = () => {
  return dayjs()
    .format('zzz')
    .replace(/[^A-Z]+/g, '');
};

export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getTimeZoneOffset = (zone: string) => {
  const timeZoneInfo = dayjs(Date.now()).tz(zone);

  return timeZoneInfo.utcOffset();
};

export const convertToOrdinal = (day: string) => {
  const lastDigit = Number(day[day.length - 1]);

  switch (lastDigit) {
    case 1:
      if (day === '11') break;
      return day.concat('st');

    case 2:
      if (day === '12') break;
      return day.concat('nd');

    case 3:
      if (day === '13') break;
      return day.concat('rd');
  }

  return day.concat('th');
};

export const formTimeString = (hours: number, minutes: number, is12HFormatUsed?: boolean) => {
  if (is12HFormatUsed) {
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const displayedHours = hours % 12 || 12;

    return `${String(displayedHours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}${amOrPm}`;
  }
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const checkIfValidTimeString = (value: string, is12HFormat: boolean) => {
  const regEx = is12HFormat
    ? `^(0[0-9]|1[0-2]):[0-5][0-9](a|p)m$`
    : `^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$`;

  return value.match(regEx) ? true : false;
};

export const is12HFormatUsed = () => {
  const date = new Date();
  const currentUserTime = date.toLocaleTimeString();
  if (currentUserTime.includes('M')) {
    return true;
  }
  return false;
};

export const convertTime12to24 = (value: string) => {
  const time = value.slice(0, value.length - 2);
  const modifier = value.slice(value.length - 2, value.length);

  const [hours, minutes] = time.split(':');
  let convertedHours;

  if (hours === '12') {
    convertedHours = '00';
  }

  if (modifier === 'pm') {
    convertedHours = parseInt(hours, 10) + 12;
  }

  return { hours: Number(convertedHours), minutes: Number(minutes) };
};

export const formDateTimeViewString = (dateTime: string) => {
  const dayOfWeek = dayjs(dateTime).format('dddd');
  const day = convertToOrdinal(dayjs(dateTime).format('D'));
  const month = dayjs(dateTime).format('MMMM');
  const year = dayjs(dateTime).format('YYYY');
  const hour = dayjs(dateTime).format('HH');
  const minute = dayjs(dateTime).format('mm');

  return `${dayOfWeek} ${day} ${month} ${year} at ${hour}:${minute}`;
};

export const isDateTimeBeforeNow = (dateTime?: string, timeZone?: string | null) => {
  if (!dateTime) {
    return false;
  }
  let timeZoneLocal: string | undefined;

  if (!timeZone) {
    timeZoneLocal = defaultTimeZone.zone;
  }

  const newTimeZone = timeZone || timeZoneLocal;

  if (!newTimeZone) {
    return false;
  }

  const dateTimeFormatted = timeZone
    ? dateTime.length > 16
      ? dateTime.slice(0, 16)
      : dateTime
    : dayjs.utc(dateTime).tz(timeZoneLocal);

  const offset = getTimeZoneOffset(newTimeZone);

  const now = dayjs();
  const time = dayjs.tz(dateTimeFormatted, newTimeZone).utcOffset(offset);

  return dayjs(time).isBefore(now);
};

export const getDefaultAutoExpireDate = () => {
  const hours = dayjs().hour() + 2;
  const minutes = dayjs().minute();

  let now = dayjs().utcOffset(0).set('hour', hours).set('minute', minutes).toISOString();
  //cut offset, millisecnds, seconds
  now = now.slice(0, now.length - 8);

  return now;
};
