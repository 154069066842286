// libs
import { css, useTheme } from 'styled-components';

// components
import { Select } from 'components';
import { Container, InvitesIndicator, OptionItem } from './styled';
import { OrganisationBadge } from 'components/OrganisationBadge';
import { NoOrganisations } from './NoOrgs';
import { CustomText } from 'typography/Text';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useState, useMemo, useRef } from 'react';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectAccountInvitesAndOrgs, selectAccountCurrentOrganisation } from 'redux/selectors';

// types
import { IOrganisationWithInvite } from 'types';

// actions
import { changeCurrentOrganisation } from 'redux/actions/account';
import { setModalContent } from 'redux/actions/app';

// utils
import { equals } from 'ramda';

export const OrgSelect = () => {
  const dispatch = useDispatch();
  const {
    palette: { fonts },
  } = useTheme();

  const { translate } = useTranslations('menu');

  const orgs = useSelector(selectAccountInvitesAndOrgs, equals);
  const currentOrg = useSelector(selectAccountCurrentOrganisation, equals);
  const hasInvites = orgs.find(org => org.role === 'Invite') !== undefined;

  // Add state for search text
  const [searchText, setSearchText] = useState('');

  // Filter organizations based on search text
  const filteredOrgs = useMemo(() => {
    if (!searchText) return orgs;
    const lowerSearch = searchText.toLowerCase().trim();
    return orgs.filter(org => (org.orgName || '').toLowerCase().includes(lowerSearch));
  }, [orgs, searchText]);

  const clearSearch = () => {
    setSearchText('');
  };

  const openJoinOrgModal = ({ record }: { record: IOrganisationWithInvite }) => {
    dispatch(setModalContent({ type: 'join-org', data: record }));
    clearSearch();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Select
        dataSource={filteredOrgs}
        valueField="orgId"
        aria-label={translate('aria_labels.select_org')}
        keyField="orgId"
        data-test="organisation-selector"
        dropdownMatchSelectWidth={false}
        getPopupContainer={() => document.body}
        dropdownStyle={{ minWidth: '350px', padding: '0', borderRadius: '4px' }}
        listHeight={320}
        dropdownRender={menu => (
          <div>
            <div style={{ padding: '8px 6px 4px 6px' }}>
              <input
                style={{
                  width: '100%',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px',
                  outline: 'none',
                }}
                placeholder={translate('aria_labels.search_orgs')}
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                ref={inputRef}
                data-test="organisation-selector-input-field"
              />
            </div>
            <div style={{ borderTop: '1px solid #e8e8e8' }}>{menu}</div>
          </div>
        )}
        onDropdownVisibleChange={visible => {
          if (visible) {
            setTimeout(() => {
              inputRef.current?.focus();
            }, 200);
          } else {
            clearSearch();
          }
        }}
        containerCss={css`
          .ant-select-item-option-active {
            .invite-indicator {
              border-color: ${({ theme }) => theme.palette.background.base};
            }
          }
        `}
        showSelectedOptionInDropdown={false}
        defaultValueFieldValue={currentOrg?.orgId}
        value={currentOrg?.orgId}
        setSelectedValue={option => {
          if (option.role !== 'Invite') {
            dispatch(changeCurrentOrganisation(option.orgId));
          }
          clearSearch();
        }}
        OptionElement={(record: IOrganisationWithInvite) => (
          <OptionItem
            onClick={record.role === 'Invite' ? () => openJoinOrgModal({ record }) : undefined}
          >
            <OrganisationBadge
              altName={record.orgName}
              inviteIndicator={record.role === 'Invite'}
            />
            <CustomText
              type="sub-heading-2"
              color={fonts.bodyDark}
              ellipsis={true}
              title={record.orgName}
              data-test-org-id={record.orgId}
              data-test-org-name={record.orgName}
            >
              {record.orgName}
            </CustomText>
          </OptionItem>
        )}
        height="3.5rem"
        notFoundContent={<NoOrganisations searchTerm={searchText} />}
        alternativeDropdown={orgs.length === 1 ? <NoOrganisations /> : undefined}
      />

      {hasInvites && <InvitesIndicator />}
    </Container>
  );
};
