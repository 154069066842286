// components
import { ColumnTitle } from 'components';
import { Description } from './Description';
import { Status } from './Status';
import { SystemsActionDropdown, USystemsActionDropdown } from './ActionDropdown';
import { SystemTags } from './Tags';
import { Text } from './Text';

// types
import { TFunction } from 'react-i18next/.';
import { OSTypes, IUnapprovedSystem, ISystemSummaryModel } from 'types';
import { translations } from 'types/translations';

// utils
import { localizedTime } from 'utils/timeFormatting';
import { ColumnType } from 'antd/lib/table';

interface ISystemEnrolledCols {
  isLoading: boolean;
  translate: TFunction<translations>;
  bulkSelectedSystems: ISystemSummaryModel[];
  selectedSystemId?: ISystemSummaryModel['systemId'];
}

export const systemsEnrolledCols = ({
  isLoading,
  translate,
  bulkSelectedSystems,
  selectedSystemId,
}: ISystemEnrolledCols): ColumnType<ISystemSummaryModel>[] => [
  {
    title: <ColumnTitle>{translate('description')}</ColumnTitle>,
    dataIndex: 'description',
    className: 'name-column',
    render: (
      description: string,
      { isEnabled, platformType, hostname, systemId, systemType }: ISystemSummaryModel
    ) => {
      const isSelected =
        bulkSelectedSystems.find(system => system.systemId === systemId) ||
        selectedSystemId === systemId;
      const systemState = isSelected ? 'active' : isEnabled ? 'default' : 'muted';
      return (
        <Description
          data-test="approved-table-description-column"
          icon={{ osVersion: platformType as OSTypes, state: systemState }}
          description={description || hostname || translate('unknown')}
          isLoading={isLoading}
          isEnabled={isEnabled}
          type={systemType}
        />
      );
    },
  },
  {
    title: <ColumnTitle>{translate('tags')}</ColumnTitle>,
    dataIndex: 'tags',
    render: (tags: ISystemSummaryModel['tags'], { isEnabled }: ISystemSummaryModel) => (
      <SystemTags tags={tags} isLoading={isLoading} isEnabled={isEnabled} />
    ),
  },
  {
    title: <ColumnTitle>{translate('connection')}</ColumnTitle>,
    dataIndex: 'connectedFrom',
    width: '16rem',
    render: (_: string, { isEnabled, connectedFrom, state, lastSeen }: ISystemSummaryModel) => (
      <Status
        data-test="approved-systems-table-connected-from-column"
        isEnabled={isEnabled}
        connectedFrom={connectedFrom}
        state={state}
        lastSeen={lastSeen}
        isLoading={isLoading}
      />
    ),
  },
  {
    title: <ColumnTitle>{translate('enrolment_key')}</ColumnTitle>,
    dataIndex: 'enrolmentKeyDescription',
    render: (enrolmentKeyDescription: string, { isEnabled }: ISystemSummaryModel) => (
      <Text isLoading={isLoading} text={enrolmentKeyDescription} isEnabled={isEnabled} />
    ),
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    className: 'action-column',
    render: (system: ISystemSummaryModel) => (
      <SystemsActionDropdown
        data-test="approved-systems-table-action-dropdown-column"
        record={system}
        isLoading={isLoading}
      />
    ),
  },
];

interface ISystemToApproveCols {
  isLoading: boolean;
  translate: TFunction<translations>;
  bulkSelectedSystems: IUnapprovedSystem[];
  selectedSystemId?: IUnapprovedSystem['systemId'];
}

export const SystemsToApproveCols = ({
  isLoading,
  translate,
  bulkSelectedSystems,
  selectedSystemId,
}: ISystemToApproveCols) => {
  return [
    {
      title: <ColumnTitle>{translate('description')}</ColumnTitle>,
      dataIndex: 'description',
      render: (
        description: string,
        { platformType, hostname, systemId, systemType }: IUnapprovedSystem
      ) => {
        const isSelected =
          bulkSelectedSystems.find(system => system.systemId === systemId) ||
          selectedSystemId === systemId;
        const systemState = isSelected ? 'active' : 'muted';

        return (
          <Description
            data-test="unapproved-table-description-column"
            icon={{ osVersion: platformType as OSTypes, state: systemState }}
            description={description || hostname || translate('unknown')}
            isLoading={isLoading}
            type={systemType}
          />
        );
      },
      ellipsis: true,
    },
    {
      title: <ColumnTitle>{translate('system_id')}</ColumnTitle>,
      dataIndex: 'systemId',
      render: (text: string) => <Text text={text} isLoading={isLoading} />,
    },
    {
      title: <ColumnTitle>{translate('enrolment_key')}</ColumnTitle>,
      dataIndex: 'enrolmentKeyDescription',
      render: (text: string) => <Text text={text} isLoading={isLoading} />,
    },
    {
      title: <ColumnTitle>{translate('details.enrolled_at')}</ColumnTitle>,
      dataIndex: 'enrolledAt',
      ellipsis: true,
      render: (enrolledAt: string) => (
        <Text text={localizedTime(enrolledAt)} isLoading={isLoading} />
      ),
    },
    {
      title: <ColumnTitle>{translate('tags')}</ColumnTitle>,
      dataIndex: 'tags',
      render: (tags: ISystemSummaryModel['tags']) => (
        <SystemTags tags={tags} isLoading={isLoading} />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (system: IUnapprovedSystem) => (
        <USystemsActionDropdown
          data-test="unapproved-systems-table-action-dropdown-column"
          record={system}
          isLoading={isLoading}
        />
      ),
    },
  ];
};
